<template>
  <div>
    <div class="backgroundOV"></div>

    <v-card outlined>
      <v-card-title class="titleLarge">Hintergrundwissen</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card>
      <v-card-text>
        <p>
          In und vor der Schuleingangsphase sollten Lernende
          <strong>ein tragfähiges Zahlverständnis</strong> aufbauen, auf das im
          immer weiter ausdifferenzierten Mathematikunterricht zurückgegriffen
          werden kann. Denn Kinder brauchen gefestigte Zahlvorstellungen, um
          kompetent und geschickt rechnen zu können (Ruwisch, 2015). Eine
          zentrale Rolle hat hierbei das Entdecken mathematischer Muster und
          Strukturen, bei denen Beziehungen zwischen Zahlen und Operationen
          ergründet und durchdrungen werden können.
        </p>
        <p>
          Ziel des Anfangsunterrichtes sollte daher sein, die Zahlvorstellungen
          der Lernenden zu systematisieren und tragfähig zu gestalten. Tragfähig
          sind Zahlvorstellungen dann, wenn sie einerseits die mathematischen
          Strukturen in angemessener Weise ausdrücken und andererseits
          anschlussfähig sind (Ruwisch, 2015). Der Aufbau des Zahlverständnisses
          ist eine Bedingung für die Entwicklung eines Operationsverständnisses
          (siehe auch Operationsverständnis), denn z. B. greifen
          halbschriftliche Rechenstrategien vor allem im Zusammenhang mit
          geschicktem Rechnen immer wieder darauf zurück (Krauthausen, 2018). Es
          zeigt sich, dass Kinder mit einem schlechter ausgeprägten
          Zahlverständnis mehr Fehler beim Rechnenlernen machen (Schulz, 2014).
        </p>
        <p>
          Eine zentrale Rolle in der Schuleingangsphase spielt die
          Zählkompetenz, da diese die Entwicklung von Grundvorstellungen von
          Zahlen ermöglicht. Das Zählen entwickelt sich maßgeblich bereits im
          vorschulischen Bereich. Diese aufzugreifen ist eine wichtige Aufgabe
          des Mathematikunterrichts der Schuleingangsphase, denn mangelnde
          Zählkompetenz kann auch mit fehlenden Erfahrungen zusammenhängen
          (Scherer & Moser Opitz, 2010).
        </p>
        <p>
          Auf den nächsten drei Seiten werden nun die drei Hintergrundfacetten
          (1. Grundvorstellungen von Zahlen, 2. Darstellungen vernetzen, 3.
          Zusammenhänge zwischen Zahlen) thematisiert.
        </p>
        <br>
        <AppLiteraturZV />
      </v-card-text>
    </v-card>
    <AppBottomNavZV
      next="/zahlverstaendnis/hintergrundwissen/grundvorstellungen"
      back="/zahlverstaendnis"
    />
  </div>
</template>

<script>
import AppBottomNavZV from "@/common/AppBottomNavZV";
import AppLiteraturZV from "@/common/AppLiteraturZV";
export default {
  components: {
    AppBottomNavZV,
    AppLiteraturZV,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
